import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import Splash from '@/layouts/Splash'
import AuthLayout from 'bh-mod/layouts/Auth'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			name: '',
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					name: 'Transactions',
					path: '/',
					meta: {
						title: 'Transactions',
						sidebar: true,
						listView: true,
						leftNav: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app'),
				},
				{
					name: 'Reservations',
					path: '/reservations',
					meta: {
						title: 'Reservations',
						sidebar: true,
						listView: true,
						leftNav: true,
						dataRequired: true,
						reservation: true,
					},
					component: () =>
						import('./views/reservation'),
				},
				{
					name: 'new Transaction',
					path: '/new',
					meta: {
						title: 'New Transaction',
						crumbName: 'New Transaction',
						sidebar: true,
						leftNav: true,
						dataRequired: true,
						sidebarCollapsed: true,
					},
					component: () =>
						import('./views/app/setuptransaction'),
				},
				{
					name: 'new Reservation',
					path: '/newReservation',
					meta: {
						title: 'New Reservation',
						crumbName: 'New Reservation',
						reservation: true,
						sidebar: true,
						leftNav: true,
						dataRequired: true,
						sidebarCollapsed: true,
					},
					component: () =>
						import('./views/reservation/setupReservation'),
				},
				{
					name: 'Saved new Transaction',
					path: '/new/:id',
					meta: {
						title: 'New Transaction',
						crumbName: 'New Transaction',
						sidebar: true,
						leftNav: true,
						dataRequired: true,
						sidebarCollapsed: true,

					},
					component: () =>
						import('./views/app/setuptransaction'),
				},
				{
					name: 'edit Transaction',
					path: '/transaction/edit/:id',
					meta: {
						title: 'Edit Transaction',
						crumbName: 'Edit Transaction',
						sidebar: true,
						leftNav: true,
						dataRequired: true,
						sidebarCollapsed: true,
					},
					component: () =>
						import('./views/app/setuptransaction'),
				},
				{
					name: 'edit Reservation',
					path: '/reservation/edit/:id',
					meta: {
						title: 'Edit Reservation',
						crumbName: 'Edit Reservation',
						sidebar: true,
						leftNav: true,
						dataRequired: true,
						sidebarCollapsed: true,
					},
					component: () =>
						import('./views/reservation/setupReservation'),
				},
				{
					name: 'editTransaction',
					path: '/transaction/:id',
					meta: {
						title: 'Edit Transaction',
						crumbName: 'Edit Transaction',
						sidebar: true,
						leftNav: true,
						editTransaction: true,
						sidebarCollapsed: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/transaction'),
				},
				{
					name: 'Reservation',
					path: '/reservation/:id',
					meta: {
						title: 'Reservation',
						crumbName: 'Reservation',
						reservation: true,
						sidebar: true,
						leftNav: true,
						editTransaction: true,
						sidebarCollapsed: true,
						dataRequired: true
					},
					component: () =>
						import('./views/reservation/details'),
				},
				{
					name: 'Transaction Settings',
					path: '/settings',
					meta: {
						title: 'Settings',
						leftNav: true,
						crumbName: 'Settings',
						sidebar: true,
						sidebarCollapsed: true,
						dataRequired: true
					},
					component: () =>
						import('./views/settings'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						leftNav: true,
						sidebar: true,
						sidebarCollapsed: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			name: 'Transaction Set Up',
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [{
				path: '/setup',
				meta: {
					title: 'Set Up Transactions',
					splashScreen: true
				},
				component: () =>
					import('./views/setup'),

			}]
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
})

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'transactions')
		error = response.parsed
		hiveRequested = true;
	}

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (splashScreen && (Object.keys(store.state.appData.transactions).length || (store.state.appData.allSettings.userApp && store.state.appData.allSettings.userApp.options && store.state.appData.allSettings.userApp.options.seenIntro))) {
			return next('/')
		}

		if (dataRequired && !Object.keys(store.state.appData.transactions).length && !Object.keys(store.state.appData.templates).length && !store.state.appData.getStarted && (!store.state.appData.allSettings || !store.state.appData.allSettings.userApp || !store.state.appData.allSettings.userApp.options || !store.state.appData.allSettings.userApp.options.seenIntro)) {
			return next('/setup')
		}
	}

	store.commit('PAGE_BUTTONS', [])
	if (to.meta.crumbName) {
		store.commit('CLEAR_CRUMBS')
	}
	if (to.meta.editTransaction && to.params.id) {
		let id = to.params.id
		if (store.state.appData.transactions && store.state.appData.transactions[id] && store.state.appData.transactions[id].archived) return next('/')
	}

	store.commit('SET_CURRENT_TRANSACTION', {})
	store.commit('SET_CURRENT_RESERVATION', {})
	store.commit('LOAD', false)

	let isPremiumPackage = store && store.getters && store.getters.isPremiumPackage || false;
	if (to.path.startsWith("/reservations") && !isPremiumPackage) {
		// restricted reservations page to only lowrise and highrise premium packages only
		return next("/");
	}

	next()
})


export default router
