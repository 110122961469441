<template>
	<div id="app">
		<BhLoading :show="loading" :loadingText="true" />
		<MediaSelector />
		<PagePreview />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import MediaSelector from 'bh-mod/components/file/MediaSelector'
import PagePreview from '@/components/forms/PagePreview'
import BhLoading from 'bh-mod/components/common/Loading'

export default {
	name: 'app',
	components: { Localization, MediaSelector, BhLoading, PagePreview },
	computed: {
		...mapGetters(['user']),
		loading() {
			return this.$store.state.loading
		}
	},
	watch: {
		'$route'(to, from) {
			if (document.querySelector('.scrolling-shadow')) {
				document.querySelector('.scrolling-shadow').classList.remove('scrolling-shadow')
			}
			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
		},
	},
	methods: {

	},

}
</script>
<style lang="scss">
#app {
	min-height: 100vh;
}

.badge-offer {
	padding: .35rem .65rem;
	text-align: center;
	display: inline-block;
	border-radius: .2rem;
	background: #aaa;
}

.badge-green {
	background-color: var(--green);
	color: white;
}

.badge-long {
	min-width: 100px;
}

.badge-purple {
	background-color: var(--purple);
	color: white;
}

.badge-red {
	background-color: var(--red);
	color: white;
}

.imp-label label {
	font-weight: bold;
}
</style>
