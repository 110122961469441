<template>
	<SubBarGlobal :currentCrumb="crumbName">
		<template v-if="$route.path == '/'">
			<a-auto-complete style="width: 300px" placeholder=" Search for a transaction..."
				:dropdown-match-select-width="false" :dropdown-style="{ width: '300px' }" option-label-prop="value"
				@search="handleSearch" class="mr-4" allowClear>
				<template slot="dataSource" v-if="instance.productType == 'highrise'">
					<a-select-option @click="optionClicked(trans)" v-for="(trans, transI) in searchResult"
						:key="trans + transI"
						:value="trans.product && trans.product.floor && trans.product.unit && (trans.product.floor.name + ' - ' + 'Unit #' + `${trans.product.unit.unitNumber} (${trans.product.unit.name})` + ` (${transI})`)">
						<div class="dF aC">
							<div class="f1" v-if="trans.product && trans.product.floor && trans.product.unit">
								<div><strong>{{ trans.product.floor.name + ' - ' + 'Unit #' +
									`${trans.product.unit.unitNumber} (${trans.product.unit.name})` }}</strong></div>
								<small class="text-sm">{{ 'Unit #' + trans.product.unit.unitNumber + ' - ' +
									`${trans.purchasers.length}` + ' Purchaser(s)' }}</small>
								<strong class="text-sm tT">
									{{ statusLookup(trans.status) }}
								</strong>
							</div>
						</div>
					</a-select-option>
				</template>

				<template slot="dataSource" v-else>
					<a-select-option @click="optionClicked(trans)" v-for="(trans, transI) in searchResult"
						:key="trans + transI"
						:value="trans.product && trans.product.lot && trans.product.unit && ('Lot ' + trans.product.lot.name + ` (${trans.product.unit.unitGroup && trans.product.unit.unitGroup.name} - ${trans.product.unit.name})` + ` (${transI})`)">
						<div class="dF aC">
							<div class="f1">
								<div><strong>{{ trans.product && trans.product.lot && trans.product.unit && ('Lot ' + trans.product.lot.name + ` (${trans.product.unit.unitGroup && trans.product.unit.unitGroup.name} -
																		${trans.product.unit.name})`) }}</strong></div>
								<small class="text-sm">{{ 'Lot #' + (trans.product && trans.product.lot && trans.product.lot.name) + ' - ' +
									`${trans.purchasers.length}` + ' Purchaser(s)' }}</small>
								<strong class="text-sm tT">
									{{ statusLookup(trans.status) }}
								</strong>
							</div>
						</div>
					</a-select-option>
				</template>

				<a-input>
					<a-icon style="stroke:#000; stroke-width:34" slot="prefix" type="search" />
				</a-input>
			</a-auto-complete>

			<div class="mr-4 d-none d-xl-block dash-divider" />
		</template>
		<PageButtons />

	</SubBarGlobal>
</template>
<script>
import SubBarGlobal from 'bh-mod/components/layout/SubBarGlobal'
import PageButtons from 'bh-mod/components/layout/SubBarGlobal/PageButtons'
import _ from "lodash";


export default {
	components: { SubBarGlobal, PageButtons },
	data() {
		return {
			loadSearch: false,
			search: '',
			searchResult: []
		}
	},
	computed: {
		crumbName() {
			if (this.$route.meta.crumbName) {
				return false
			}
			if (this.$route.path === '/') {
				return this.$store.state.appData.transactionType.label
			}
			if (this.$route.path.includes('reservations')) {
				return 'Reservations'
			}
			return this.$store.state.appData.filter.label
		},
		transactions() {
			return Object.values(this.$store.state.appData.transactions)
		},
		pageButtons() {
			return this.$store.state.pageButtons
		},
		theApp() {
			return this.$store.state.theApp || {}
		},

		instance() {
			return this.$store.state.instance
		},
	},
	methods: {
		optionClicked(data) {
			this.search = '';
			this.searchResult = []
			if(data.status === 'draft'){
				this.$router.push(`/new?draft=${data.id}`)
			} else {
				this.$store.commit('SET_CURRENT_TRANSACTION', data)
				this.$router.push(`/transaction/${data.id}`)
			}
		},
		handleSearch(input) {
			this.search = input
			this.searchDebounce()
		},

		searchDebounce: _.debounce(function () {
			if (this.search) {
				this.fetchSearchResult();
			}
		}, 1000),

		async fetchSearchResult() {
			this.$nprogress.start();
			try {
				const { data } = await this.$api.get(`/transactions/:instance?_q=${this.search}`)
				this.searchResult = data.items.filter(d => !d.archived) || [];
			} catch (err) {
				this.$message.error(this.$err(err))
			}
			this.$nprogress.done();
		},

		statusLookup(st) {
			let textLookup = {
				void: 'Voided',
				created: 'Pending'
			}
			return textLookup[st] || st
		}
	},
	created() {

	}
}
</script>

<style lang="scss">
.ant-form-horizontal.search-horizontal {
	label {}

	.ant-form-item-control-wrapper {
		flex: 1;
	}

	.ant-form-item {
		display: flex;
	}

	.ant-form-item-label {
		min-width: 100px;
	}
}
</style>
